import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { PrePurchaseConstants } from '../../adapters/helpers/Constants';
import { customJsonstringify } from '../../adapters/helpers/Utils';

const PrePurchase = (props) => {
    const title = props?.document?.fields?.title;
    const areaLabel = props?.document?.fields?.areaLabel;
    const jebbitUrl = process.env.JEBIT_URL;
    const redirectionUrl = props?.document?.fields?.redirectionUrl;
    const [loadStatus, setLoadStatus] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            handleJebbitLoad();
        }, 5000);
    }, [loadStatus])

    function embedJebbit(t) {
        function e(t) {
            var e = 'attach' === t ? window.addEventListener : window.removeEventListener;
            e('DOMContentLoaded', i, !1), e('load', i, !1), e('scroll', i, !1), e('resize', i, !1)
        }
        var n = document.querySelector(t);
        function i() {
            (function (t) {
                var e = t.getBoundingClientRect(), n = t.clientHeight / 2, i = t.clientWidth / 2; return e.top >= 0 && e.left >= 0 && e.top <= (window.innerHeight || document.documentElement.clientHeight) - n && e.left <= (window.innerWidth || document.documentElement.clientWidth) - i
            })(n) && n.contentWindow?.postMessage('startJebbitCampaign', '*')
        }
        window.addEventListener('message', function (t) {
            if (t?.data && Array.isArray(t?.data?.products) && t?.data?.products.length > 0 && redirectionUrl) {
                this.sessionStorage.setItem(PrePurchaseConstants.jebbitData, customJsonstringify(t?.data?.products));
                this.window.parent.location.href = `${redirectionUrl}`;
            }
            t && ('jebbitSessionCreated' === t.data ? e('remove') : 'jebbitCampaignLoaded' === t.data ? i() : t.data && 'redirect' === t.data.action && t.data.options && t.data.options.link && (window.location.href = t.data.options.link))
        }, !1), e('attach')
    }

    const handleJebbitLoad = () => {
        embedJebbit('.jebbit-iframe');
    }

    return <iframe title={`${title}`} aria-label={`${areaLabel}`} className='jebbit-iframe' src={`${jebbitUrl}`} seamless="true"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
        loading='lazy'
    ></iframe>;
}

PrePurchase.propTypes = {
    document: PropTypes.object
};

export default mediaQueryHOC(PrePurchase);
// This export is for unit testing (do not remove) :
export const PrePurchaseTest = PrePurchase;